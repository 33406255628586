const Sentry = require('@sentry/nextjs');

/**
 * Capture an error
 * @param err
 */
function capture(err) {
  if (process.env.TRACK_ERRORS) {
    Sentry.captureException(err);
  }
  console.error('Error received %s', err);
}

module.exports = capture;
