import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { createWixConnection } from 'actions/channel';
import { attachUserCatalog } from 'actions/user';
import message from '../components/utils/message';
import capture from '../tools/capture';
import Home from './home';
import { useRouterWithLang } from 'hooks/useRouterWithLang';

export default function Pages() {
  const router = useRouterWithLang();
  const { state, code } = router.query;
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((s) => s.isLoggedIn);

  const handleWixConnection = useCallback(async () => {
    if (code && state && isLoggedIn) {
      try {
        let response = await dispatch(
          createWixConnection({
            catalogId: state,
            publicToken: code,
          })
        );
        await dispatch(attachUserCatalog()); // Fetch catalog data
        await router.push(`/channel/${state}/edit/channel`);
      } catch (error) {
        capture(error);
        console.log('error', error);
        message.error('Errore');
      }
    }
  }, [code, dispatch, isLoggedIn, router, state]);
  useEffect(() => {
    handleWixConnection();
  }, [handleWixConnection]);
  return <Home />;
}
